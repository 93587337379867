<template>
    <section >
      <apexchart :height="computeHeight" type="bar" :options="{...chartOptions, colors: colors}" :series="series"></apexchart>
    </section>
</template>

<script>

export default {
    props: {
        series: {
            type: Array,
            default: [{
                data: [{
                    x: '',
                    y: 0
                }]
            }]
        },
        colors: {
            type: Array,
            default: () => (['#0061BA'])
        }
    },
    data: () => ({
        chartOptions: {
            plotOptions: {
                bar: {
                    borderTopRightRadius: 4,
                    borderBottomRightRadius: 4,
                    horizontal: true,
                }
            },
            chart: {
                height: '100%',
                width: '100%',
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 500,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                }
            },
            responsive: [
                {
                    breakpoint: 1000,
                    options: {
                        plotOptions: {
                        bar: {
                            horizontal: false
                        }
                        },
                        legend: {
                            position: "bottom",
                        },
                        xaxis: {
                            labels: {
                                rotate: 280,
                                hideOverlappingLabels: false,
                                maxHeight: 180,
                            }
                        },
                    },
                }
            ]
        }
    }),
    computed: {
        computeHeight(){
            return this.series[0].data.length < 10 ?  this.series[0].data.length * 80 : 'auto'
        }
    }
};
</script>