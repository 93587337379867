<template>
    <section id="reporting">
        <v-row class="mb-5">
            <v-col cols="12" md="4" v-for="(item, i) in items" :key="i">
                <TopCard :item="item"/>
            </v-col>
        </v-row>
        
        <v-card class="custom-border border pa-5 mb-5">
            <ApexBarGraph :series="chartData"/>
        </v-card>

        <!-- <v-select
          :items="filter"
          v-model="selected"
          hide-details
          solo
          dense 
          flat
          class="col-xl-3 col-lg-4 f14 fw500 poppins custom-border general-custom-field mb-5">
        </v-select> -->
        <!-- <canvas ref="chart"  style="width: 300px;"></canvas> -->
        <ReportingTableVue
            @closeList="init" 
            @changeGraphData="changeGraphData"/>
    </section>
</template>

<script>
import { mapActions } from 'vuex';
import TopCard from '@/components/reusable/TopCard.vue'
import ReportingTableVue from '@/components/teacher/reporting/ReportingTable.vue';
import ApexBarGraph from "@/components/reusable/graphs/ApexBarGraph.vue"

export default ({
    components: {
        TopCard,
        ReportingTableVue,
        ApexBarGraph
    },
    data: () => ({
        items: [{
            id: 'total_enrolled_users',
            title: 'Total Enrolled Users',
            count: '-',
            color: '#B5221033', 
            outline: '#B52210'
        },
        {
            id: 'published_courses',
            title: 'Published Courses',
            count: '-',
            color: '#F8835E33',
            outline: '#F8835E'
        },
        /* {
            id: 'total_admins_and_facilitators',
            title: 'Total Admins & Facilitators',
            count: '-',
            color: '#E49E3233',
            outline: '#E49E32'
        } */
    ],
        courses: {},
        chartData: [{
            name: 'Enrollee Count',
            data: [{
                x: '',
                y: 0
            }]
        }],
        loading: false,
        table_items: [],
        filter: 'All',
        selected_course: null,
    }),
    methods: {
        ...mapActions('instructor', ['getInstructorReportingStats', 'getInstructorAllCoursesReportingStats', 'getInstructorEnrolledUsersAction', 'getRegistrationFieldsAction', 'getInstructorFilteredEnrolledUsersAction']),
        
        changeGraphData(val){
            this.filter = val

            if(this.headers.length > 2) {
                this.headers.pop()
            }
            if(this.filter.filter !== 'All') {
                this.headers.push({ text: `${this.filter.filter.toUpperCase()}`, value: `${this.filter.filter.toLowerCase()}`, align: 'center' })
            }

            this.getInstructorFilteredEnrolledUsersAction({ filter: val.filter, course_id: val.course_id}).then(res => {
                
                // this.chartData = {
                //     labels: Object.keys(res),
                //     datasets: [
                //         {
                //             label: 'No of Enrollees',
                //             data: Object.values(res),
                //             backgroundColor: this.$vuetify.theme.currentTheme.primary,
                //             borderColor: this.$vuetify.theme.currentTheme.primary,
                //             borderWidth: 1
                //         }
                //     ]
                // }
            })

            this.table_items = this.table_items
        },


        init() {
            this.selected_course = null
            this.table_items = []
            this.getInstructorAllCoursesReportingStats().then(res => {
                let courses = {}
                let _data = []
                res.forEach(item => {
                    courses[item.title ? item.title : 'Untitled'] = item.enrollments_count

                    _data.push({
                        x: item.title,
                        y: item.enrollments_count
                    })

                    this.chartData = [{
                        name: 'Enrollee Count',
                        data: [..._data]
                    }]
                })
            })
        }
    },
    mounted(){

        // this.chartData.datasets[0].backgroundColor = this.$vuetify.theme.currentTheme.primary
        // this.chartData.datasets[0].borderColor = this.$vuetify.theme.currentTheme.primary
        
        // stats card
        this.getInstructorReportingStats().then(res => {
            for (const [key, value] of Object.entries(res)) {
                this.items[this.items.findIndex(item  => item.id === key)].count = value
            }
        })

        this.init()
    },
})
</script>