<template>
    <section class="border pt-3">
        <section class="d-flex flex-wrap justify-space-between mb-3">
            <section class="d-flex flex-row align-center pa-0 my-2">
                <v-text-field
                    solo
                    dense
                    flat
                    class="custom-border general-custom-field f14 poppins fw500 px-0"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Search"
                    v-model="search"
                />
              <v-btn color="primary" class="poppins" @click="() => {course_id === null ? getCourses() : viewEnrolleeList(course_id)}"> Search</v-btn>
            </section>
            <v-btn dense outlined color="primary" @click="exportTable" class="my-2 ml-auto" :loading="loading1">
                Export List
            </v-btn>
        </section>
        <section class="d-flex flex-row justify-space-between align-center mb-3 mr-2" v-if="course_id">
            <v-btn @click="() => {
                course_id = null
                title = null
                selected = 'All'
                $emit('closeList')
            }"
                class="flat fw600" dense color="primary" text>
                <v-icon>
                    mdi-chevron-left
                </v-icon>
                {{ title }}
            </v-btn>
            <!-- <v-select
                :items="filter"
                v-model="selected"
                hide-details
                solo
                outlined
                dense 
                flat
                class="col-xl-3 col-lg-4 f14 fw500 poppins custom-border general-custom-field">
            </v-select> -->
        </section>
        <v-expand-transition>
            <section>
                <v-data-table
                    :headers="course_id === null ? instructor_reporting_courses_tbl : instructor_reporting_enrolled_users_tbl"
                    :items="items"
                    class="elevation-1 custom-border border"
                    hide-default-footer
                    :loading="loading"
                    :items-per-page.sync="paginate"
                    >
                    <template v-slot:item.title="{ item }">
                        <span @click="() => {
                            if(item.enrollments_count > 0) {
                                course_id = item.id
                                title = item.title
                                $emit('changeGraphData', { filter: 'All', course_id: item.id})
                                search = ''
                                viewEnrolleeList(item.id)
                            }
                        } " text class="roboto text-wrap pa-2 text-right ma-0 primary--text cursor-pointer" :style="'letter-spacing: normal; justify-content: flex-start;'">
                            {{ item.title }}
                        </span>
                    </template>
                    <template v-slot:item.start="{ item }">
                        {{ item.start ? $dateFormat.mmDDyy(item.start) : '-' }}
                    </template>
                    <template v-slot:item.end="{ item }">
                        {{ item.end ? $dateFormat.mmDDyy(item.end) : '-' }}
                    </template>
                </v-data-table>
                <FormPagination 
                    :pageCount="pageCount" 
                    :page="page"
                    :paginate="paginate"
                    @page="(e) => {page = e, course_id === null ? getCourses() : viewEnrolleeList(course_id)}" 
                    @paginate="(e) => {paginate = e, page = 1, course_id === null ? getCourses() : viewEnrolleeList(course_id) }"/>
            </section>
        </v-expand-transition>
    </section>
</template>

<script>
import Section from '@/views/admin/Section.vue'
import { mapActions, mapMutations } from 'vuex'
import { instructor_reporting_courses_tbl, instructor_reporting_enrolled_users_tbl } from "@/constants/tblheaders/reporting"

export default {
  components: { Section },
    data: () => ({
        course_id: null,
        title: '',
        selected: 'All',
        filter: [],
        headers: [],
        pageCount: 1,
        paginate: '10',
        page: 1,
        headers: [
            { text: "COURSE", value: "title"},
            { text: "ENROLLED USERS", value: "enrollments_count", align: 'center' },
            { text: "TRAINING HOURS", value: "training_hours", align: 'center' },
            { text: "DATE CONDUCTED", value: "start", align: 'center' },
            { text: "END", value: "end", align: 'center' },
        ],
        loading: false,
        loading1: false,
        items: [],
        search: '',
        instructor_reporting_courses_tbl,
        instructor_reporting_enrolled_users_tbl
    }),
    methods: {
        ...mapActions('instructor', [
            'getInstructorCoursesReportingStats',
            'getRegistrationFilterAction',
            'getInstructorFilteredEnrolledUsersAction',
            'getInstructorEnrolledUsersAction',
            'exportInstructorCoursesReportingStats',
            'exportInstructorEnrolledUsersAction'
        ]),

        ...mapMutations(['alertMutation']),

        getCourses(){
            this.loading = true
            this.getInstructorCoursesReportingStats({ search: this.search, paginate: Number(this.paginate), page: this.page }).then(res => {
                this.items = []
                res.data.forEach(item => {
                    let _course = {}
                    _course['id'] = item.id
                    _course['title'] = item.title ? item.title : 'Untitled'
                    _course['enrollments_count'] = item.enrollments_count
                    _course['training_hours'] = item.training_hours
                    _course['start'] = item.available_until ? item.available_until.start_date ? item.available_until.start_date : null : null
                    _course['end'] = item.available_until ? item.available_until.end_date ? item.available_until.end_date : null : null

                    this.items.push(_course)
                })

                this.pageCount = res.last_page
                this.paginate = res.per_page.toString()
                this.page = res.current_page

            }).catch(() => {
                this.items = []
            }).finally(() => {
                this.loading = false
            })
        },
        
        getLabel(_label) {
            let initials = []
            let word = _label.split(' ')
            word.forEach(item => {
                initials.push(`${item.toLowerCase()}`)
            })
            let label = initials.join(' ')
            return  `${label}`
        },

        viewEnrolleeList(item) {
            this.loading = true
            this.items = []
            this.getInstructorEnrolledUsersAction({ course_id: item, search: this.search, paginate: Number(this.paginate), page: this.page }).then(res => {
                res.data.forEach(user => {
                    let _user = {}
                    _user['user'] = `${ user.first_name ? user.first_name : 'User'} ${ user.last_name ? user.last_name : 'Account'} ${ user.suffix ? user.suffix : ''}`
                    _user['email'] = user.email
                    if(user.data) {
                        for (const [key1, value1] of Object.entries(user.data)) {
                            for (const [key2, value2] of Object.entries(value1)) {
                                _user[this.getLabel(`${key2.replaceAll('_', " ")}`)] = value2
                            }
                        }
                    }
                    this.items.push(_user)
                })

                this.pageCount = res.last_page
                this.paginate = res.per_page.toString()
                this.page = res.current_page
            }).finally(() => {
                this.loading = false
            })
        },

        exportTable(){
            this.loading1 = true
            
            if(this.course_id === null) {
                this.exportInstructorCoursesReportingStats({ search: this.search }).catch(() => {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                }).finally(() => {
                    this.loading1 = false
                })
            } else {
                this.exportInstructorEnrolledUsersAction({ course_id: this.course_id, search: this.search }).catch(() => {
                    this.alertMutation({
                        show: true,
                        text: 'Something went wrong.',
                        type: "error"
                    })
                }).finally(() => {
                    this.loading1 = false
                })
            }
        }
    },
    mounted() {
        this.getCourses()
    },
    watch: {
        course_id(val){
            this.search = ''

            this.pageCount = 1
            this.page = 1

            if(!val) {
                this.getCourses()
            }
            
        },

        selected(val) {
            this.$emit('changeGraphData', { filter: val, course_id: this.course_id})
        },
    }
}
</script>