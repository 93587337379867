const instructor_reporting_courses_tbl = [
    { text: "COURSE", value: "title", sortable: false},
    { text: "ENROLLED USERS", value: "enrollments_count", align: 'center', sortable: false },
    { text: "TRAINING HOURS", value: "training_hours", align: 'center', sortable: false },
    { text: "DATE CONDUCTED", value: "start", align: 'center', sortable: false },
    { text: "END", value: "end", align: 'center', sortable: false },
]

const instructor_reporting_enrolled_users_tbl = [
    { text: "USER", value: "user", sortable: false},
    { text: "EMAIL", value: "email", align: 'center', sortable: false},
]

export { instructor_reporting_courses_tbl, instructor_reporting_enrolled_users_tbl }